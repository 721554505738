import Head from "next/head";
import { useRouter } from "next/router";
import Script from 'next/script'

export default function HeadCustom({ title, description, ogTitle, ogDesc, ogImg, children }) {
    const router = useRouter()

    const clearDynamicParam = (path = "", asPath) => {
        if (path.includes('vybor-na-genplane') || path.includes('promotions')) {
            return path.replace("[section]", "").replace("[id]", "")
        } else {
            return path.includes('catalog') ? router.pathname + "/" : asPath
        }
    }

    return (
        <>
            <Head>
                <title>{title}</title>

                <meta name="description" content={description} />

                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5" />

                <link type="image/x-icon" rel="shortcut icon" href="/favicon.ico" />

                <link type="image/png" sizes="16x16" rel="icon" href="/16x16@2x.png" />
                <link type="image/png" sizes="32x32" rel="icon" href="/32x32@2x.png" />
                <link type="image/png" sizes="48x48" rel="icon" href="/48х48@2x.png" />
                <link type="image/png" sizes="120x120" rel="icon" href="/120х120.png" />

                <link type="image/png" sizes="192x192" rel="icon" href="/192x192.png" />

                <link sizes="60x60" rel="apple-touch-icon" href="/60х60.png" />
                <link sizes="76x76" rel="apple-touch-icon" href="/76х76.png" />
                <link sizes="152x152" rel="apple-touch-icon" href="/152х152.png" />
                <link sizes="180x180" rel="apple-touch-icon" href="/180x180.png" />

                <link color="#1e324b" rel="mask-icon" href="/favicon.svg" />

                <link rel="manifest" href="/manifest.json" />
                <meta name="theme-color" content="#1e324b" />

                <meta property="og:title" content={ogTitle} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`${process.env.NEXT_PUBLIC_API_LOCAL_PATH}${clearDynamicParam(router.pathname, router.asPath)}`} />
                <meta property="og:image" content={ogImg} />
                <meta property="og:description" content={ogDesc} />

                <link rel={"canonical"}
                    href={`${process.env.NEXT_PUBLIC_API_LOCAL_PATH}${clearDynamicParam(router.pathname, router.asPath)}`} />

            </Head>
            {process.env.NEXT_PUBLIC_ENABLE_GTM === 'true' && (
                <noscript>
                    <iframe
                        src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_KEY}`}
                        height="0"
                        width="0"
                        style={{ display: "none", visibility: "hidden" }}
                    />
                </noscript>
            )}
            {children}
            {process.env.NEXT_PUBLIC_ENABLE_CALLTOUCH === 'true'
                &&
                <>
                    <Script
                        src={'https://code.jquery.com/jquery-3.6.0.slim.min.js'}
                        strategy={'lazyOnload'}
                    />
                </>}

            {/*process.env.NEXT_PUBLIC_ENABLE_GTM === 'true' && <Script
                dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-5Z47W6Z');`
                }}
            />*/}

        </>
    )
}
